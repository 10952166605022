<template>
  <div class="CortexDslView">
    <Card class="card">
      <template #body>
        <DataTable
          v-if="cortex.search.data && cortex.searchTotalCount > 0"
          :columns="columns"
          :data-test-id="$testId('dataTable')"
          :rows="rows"
        />
        <div
          v-else-if="!cortex.isLoading"
          class="noResults"
          :data-test-id="$testId('noResults')"
        >
          <HeadingMedium>No results found</HeadingMedium>
          <IconButton
            :data-test-id="$testId('clearPromptButton')"
            hasOutline
            icon="ideaBot"
            :scale="1.25"
            text="Try a different prompt"
            type="secondary"
            @click="() => cortex.updateSearchTerm('')"
          />
        </div>
      </template>
      <template #footer>
        <ParagraphBody
          v-if="cortex.searchTotalCount > 0"
          class="footerCopy"
          :data-test-id="$testId('footerCopy')"
        >
          {{ footerCopy }}
        </ParagraphBody>
      </template>
    </Card>
    <div class="controls">
      <!-- @todo: Buttons are for presentation only, will be wired up later -->
      <DropdownActions
        buttonText="Download"
        isDisabled
        :items="[]"
      />
      <ButtonSecondary
        isDisabled
        :text="`View In ${cortex.itemType} List`"
      />
      <ButtonSecondary
        isDisabled
        text="View In Reports"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
/* eslint-disable no-param-reassign */
import {
  ButtonSecondary,
  Card,
  DropdownActions,
  HeadingMedium,
  IconButton,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed } from 'vue';
import { useCortexStore } from '@/store/cortex';
import { useRouter } from 'vue-router';
import DataTable, { type DataTableColumn, type DataTableRow } from './DataTable.vue';

const cortex = useCortexStore();
const router = useRouter();
const emit = defineEmits(['navigation']);

const maxVisibleItems = 10;

/**
 * @example
 * | Displaying 1 - 10 of 100 records
 * | Displaying 1 - 100 of 100 or more records
 */
const footerCopy = computed(() => (
  `Displaying ${
    Math.min(1, cortex.searchTotalCount)
  } - ${
    Math.min(cortex.searchTotalCount, maxVisibleItems)
  } of ${cortex.searchTotalCount}${!!cortex.searchLimit && cortex.searchTotalCount === cortex.searchLimit ? ' or more' : ''} records`
));

const idKey = computed(() => (cortex.itemType ? `${cortex.itemType}.id` : ''));
const idField = computed(() => (
  cortex.search.data?.results?.[0]?.fields
    ?.find(({ field }) => field === idKey.value)
));
const getItemPath = (id: string) => {
  if (!idField.value) return '';

  const itemType = cortex.itemType as string;

  if (/(device|user)(?!_group)/.test(itemType)) return `/${itemType}s/${id}/details`;

  if (/(device|user)_group/.test(cortex.itemType as string)) {
    // e.g. 'device_group' -> ['device', 'group']
    const [type] = itemType.split('_');

    return `/groups/${type}/${id}/details`;
  }

  if (/ldap/.test(itemType)) {
    return `/${itemType}/ldap_server/${id}/details`;
  }

  if (/sso/.test(itemType)) {
    return `/applications/${id}/details`;
  }

  return '';
};

const handleItemClick = (id: string) => {
  router.push({
    path: getItemPath(id),
  });
  emit('navigation');
};

const columns = computed<DataTableColumn[]>(() => (
  !cortex.search.data || !cortex.schema.data
    ? []
    : cortex.search.data!.metadata.schema.reduce((
      acc,
      { field: key },
    ) => {
      if (key !== idKey.value) {
        /**
         * for some search responses the schema may not be just a single item type
         * e.g. 'device' & 'device_disk_summary' - this pulls the schema based on the item type
         * for each field and maps the appropriate label to each column
         */
        const [itemType] = key.split('.');
        const itemSchema = cortex.schema.data![itemType];
        const field = itemSchema!.fields
          .find(({ name }) => name === key);

        acc.push({
          key,
          label: field!.label,
        });
      }

      return acc;
    }, [] as DataTableColumn[])
));

const rows = computed<DataTableRow[]>(() => (
  (cortex.search.data?.results || []).slice(0, maxVisibleItems).reduce((
    results,
    { fields },
  ) => {
    results.push(fields.reduce((row, { field, value }) => {
      if (field !== idKey.value) {
        row[field] = value ?? '—';
      }

      if (idField.value?.field === field) {
        row.ariaLabel = `Click to view ${cortex.itemType}`;
        row.onClick = () => handleItemClick(value);
      }

      return row;
    }, {} as DataTableRow));

    return results;
  }, [] as DataTableRow[])
));
</script>

<script lang="ts">
export default { name: 'CortexDslView' };
</script>

<style scoped>
.footerCopy {
  font-size: var(--jcBodySmall);
  font-style: italic;
  text-align: right;
}

.card {
  box-shadow: var(--jc-box-shadow);
  margin-bottom: var(--jc-spacer);
}

.controls {
  display: flex;
  gap: var(--jc-spacer);
  justify-content: flex-end;
}

.noResults {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
}

.noResults__img {
  max-width: 300px;
}
</style>
