<template>
  <InputSearch
    v-model="input"
    :autofocus="true"
    :focus="true"
    :placeholder="placeholder"
    @update:model-value="onInput"
  >
    <template #button>
      <IconButton
        v-if="input"
        aria-label="Clear search"
        hasNoBackground
        icon="closeCircle"
        @click="onInput('')"
      />
    </template>
  </InputSearch>
</template>

<script>
import { IconButton, InputSearch } from '@jumpcloud/ui-components';
import { debounce } from 'lodash';
import { ref, toRefs, watch } from 'vue';
import { useCortexStore } from '@/store/cortex';
import { useFeatureFlags } from '@/feature-flags';
import { useInputRecorder } from './useInputRecorder';
import { useVisibilityListener } from './useVisibilityListener';

const inputDebounceTimeMS = 400;

export default {
  name: 'GoToModalInput',

  components: {
    IconButton,
    InputSearch,
  },

  props: {
    resultCount: {
      type: Number,
      default: 0,
    },
    showModal: Boolean,
  },

  emits: ['update:input'],

  setup(props, { emit }) {
    const { showModal, resultCount } = toRefs(props);
    const input = ref('');
    const { hasFeatureFlag } = useFeatureFlags();
    const placeholder = hasFeatureFlag('cortexRelease')
      ? 'Ask a question with JumpCloud AI'
      : 'Find pages, features, users and devices';

    useInputRecorder(input, showModal, resultCount);

    const onInputDebounced = () => {
      emit('update:input', input.value);
    };

    const debouncedOnInput = debounce(onInputDebounced, inputDebounceTimeMS);

    const onInput = (contents) => {
      input.value = contents;
      debouncedOnInput();
    };

    const { onVisible } = useVisibilityListener(showModal);
    onVisible(() => {
      input.value = '';
      onInputDebounced();
    });

    if (hasFeatureFlag('cortexRelease')) {
      const cortex = useCortexStore();

      watch(cortex.searchTerm, ({ value }) => {
        onInput(value);
      });
    }

    return {
      input,
      onInput,
      placeholder,
    };
  },
};
</script>

<style scoped>
:deep(input) {
  font-size: var(--jcBodyLarge);
}

:deep(input)::-webkit-search-cancel-button {
  display: none;
}
</style>
