import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import { createCacheBuilder } from '@jumpcloud-ap/cache-helpers';

export interface SettingsResponse {
  SUPPORT_LEVEL?: string | '',
}

class SettingsApi {
  protected baseUrl = '/api/settings';
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async getGlobalSettings(): Promise<SettingsResponse> {
    const response = await this.axiosInstance.get<SettingsResponse>(this.baseUrl);

    return response?.data;
  }
}

class CachedSettingsApi extends SettingsApi {
  private builder = createCacheBuilder(window.queryClient, this);

  getGlobalSettings = this.builder.buildQuery({
    fn: super.getGlobalSettings,
    buildKey: () => [this.baseUrl],
  });
}

export default new CachedSettingsApi();
