import AlertsIconComponent from '@/components/AlertsIconComponent.vue';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';

// Images
import iconAccessRequests from '@/img/icons/nav/icon-nav-accessrequests.svg';
import iconAccount from '@/img/icons/nav/icon-nav-account.svg';
import iconActiveDirectory from '@/img/icons/nav/icon-nav-activedirectory.svg';
import iconChat from '@/img/icons/nav/icon-nav-chat.svg';
import iconCommands from '@/img/icons/nav/icon-nav-commands.svg';
import iconConditionalList from '@/img/icons/nav/icon-nav-conditionallist.svg';
import iconConditionalPolicies from '@/img/icons/nav/icon-nav-conditionalpolicies.svg';
import iconConfigurationGroups from '@/img/icons/nav/icon-nav-configuration-groups.svg';
import iconDeviceGroups from '@/img/icons/nav/icon-nav-device-groups.svg';
import iconDeviceTrust from '@/img/icons/nav/icon-nav-device-trust.svg';
import iconDevices from '@/img/icons/nav/icon-nav-devices.svg';
import iconDirectories from '@/img/icons/nav/icon-nav-directories.svg';
import iconDirectoryInsights from '@/img/icons/nav/icon-nav-insights-directory.svg';
import iconGetStarted from '@/img/icons/nav/icon-nav-discover.svg';
import iconGroups from '@/img/icons/nav/icon-nav-groups.svg';
import iconHome from '@/img/icons/nav/icon-nav-home.svg';
import iconHris from '@/img/icons/nav/icon-nav-hris.svg';
import iconIdP from '@/img/icons/nav/icon-nav-idp.svg';
import iconLdap from '@/img/icons/nav/icon-nav-ldap.svg';
import iconMdm from '@/img/icons/nav/icon-nav-mdm.svg';
import iconMfa from '@/img/icons/nav/icon-nav-mfa.svg';
import iconOsManagement from '@/img/icons/nav/icon-nav-os-management.svg';
import iconPasswordManager from '@/img/icons/nav/icon-nav-password-manager.svg';
import iconPasswordPolicies from '@/img/icons/nav/icon-nav-password-policies.svg';
import iconPolicies from '@/img/icons/nav/icon-nav-policies.svg';
import iconRadius from '@/img/icons/nav/icon-nav-radius.svg';
import iconReports from '@/img/icons/nav/icon-nav-reports.svg';
import iconSaasManagement from '@/img/icons/nav/icon-nav-saas-management.svg';
import iconSettings from '@/img/icons/nav/icon-nav-settings.svg';
import iconSoftwareApps from '@/img/icons/nav/icon-nav-softwareApps.svg';
import iconSso from '@/img/icons/nav/icon-nav-sso.svg';
import iconUsers from '@/img/icons/nav/icon-nav-users.svg';

const NavPanelLinkList = {
  accessRequestsLink: new NavPanelLinkListItem({
    title: 'Access Requests',
    href: '#/accessrequests',
    imageComponent: iconAccessRequests,
    featureFlag: 'workflowsManagement',
    isNew: true,
  }),
  accountLink: new NavPanelLinkListItem({
    title: 'Account',
    href: '#/account',
    imageComponent: iconAccount,
  }),
  activeDirectoryLink: new NavPanelLinkListItem({
    title: 'Active Directory',
    href: '#/directories/active-directory',
    imageComponent: iconActiveDirectory,
  }),
  alertsLink: new NavPanelLinkListItem({
    title: 'Alerts',
    href: '#/alerts',
    imageComponent: AlertsIconComponent,
    isNew: true,
    featureFlag: 'deviceHealthMonitoring',
  }),
  assetManagementLink: new NavPanelLinkListItem({
    title: 'Asset Management',
    href: '#/asset-management/devices',
    imageComponent: iconSaasManagement,
    featureFlag: 'assetManagementMFE',
    isNew: true,
  }),
  chatLink: new NavPanelLinkListItem({
    title: 'Chat',
    imageComponent: iconChat,
  }),
  cloudDirectoriesLink: new NavPanelLinkListItem({
    title: 'Cloud Directories',
    href: '#/directories/cloud-directories',
    imageComponent: iconDirectories,
  }),
  commandsLink: new NavPanelLinkListItem({
    href: '#/commands',
    imageComponent: iconCommands,
    showForCommandRunners: true,
    title: 'Commands',
  }),
  conditionalListsLink: new NavPanelLinkListItem({
    title: 'Conditional Lists',
    href: '#/security/iplists',
    imageComponent: iconConditionalList,
  }),
  configurationGroupsLink: new NavPanelLinkListItem({
    title: 'Policy Groups',
    href: '#/groups/configuration',
    imageComponent: iconConfigurationGroups,
  }),
  conditionalPoliciesLink: new NavPanelLinkListItem({
    title: 'Conditional Policies',
    href: '#/security/authnpolicies',
    imageComponent: iconConditionalPolicies,
    featureId: 'zeroTrust',
  }),
  deviceTrustLink: new NavPanelLinkListItem({
    title: 'Device Trust',
    href: '#/deviceTrust',
    imageComponent: iconDeviceTrust,
    featureId: 'deviceTrust',
  }),
  configurationsLink: new NavPanelLinkListItem({
    title: 'Policy Management',
    href: '#/configurations',
    imageComponent: iconPolicies,
    featureId: 'osPatchManagement',
  }),
  deviceGroupsLink: new NavPanelLinkListItem({
    title: 'Device Groups',
    href: '#/groups/device',
    imageComponent: iconDeviceGroups,
  }),
  devicesLink: new NavPanelLinkListItem({
    title: 'Devices',
    href: '#/devices',
    imageComponent: iconDevices,
  }),
  directoryInsightsLink: new NavPanelLinkListItem({
    title: 'Directory',
    href: '#/insights',
    imageComponent: iconDirectoryInsights,
  }),
  getStartedLink: new NavPanelLinkListItem({
    title: 'Get Started',
    href: '#/getStarted',
    imageComponent: iconGetStarted,
  }),
  homeLink: new NavPanelLinkListItem({
    title: 'Home',
    href: '#/home',
    imageComponent: iconHome,
  }),
  hrDirectoriesLink: new NavPanelLinkListItem({
    title: 'HR Directories',
    href: '#/directories/hr-directories',
    imageComponent: iconHris,
  }),
  identityProvidersLink: new NavPanelLinkListItem({
    title: 'Identity Providers',
    href: '#/directories/identity-providers',
    imageComponent: iconIdP,
  }),
  ldapLink: new NavPanelLinkListItem({
    title: 'LDAP',
    href: '#/ldap',
    imageComponent: iconLdap,
  }),
  mfaLink: new NavPanelLinkListItem({
    title: 'MFA Configurations',
    href: '#/settings/mfa',
    imageComponent: iconMfa,
  }),
  mdmLink: new NavPanelLinkListItem({
    title: 'MDM',
    href: '#/mdm',
    imageComponent: iconMdm,
  }),
  osManagement: new NavPanelLinkListItem({
    featureFlag: 'osManagement',
    href: '#/osmanagement',
    imageComponent: iconOsManagement,
    isNew: true,
    title: 'OS Management',
  }),
  passwordPoliciesLink: new NavPanelLinkListItem({
    title: 'Password Policies',
    href: '#/passwordpolicies',
    imageComponent: iconPasswordPolicies,
    featureFlag: 'passwordPoliciesMfe',
    isNew: true,
  }),
  passwordManagerLink: new NavPanelLinkListItem({
    title: 'Password Manager',
    href: '#/passwordmanager',
    imageComponent: iconPasswordManager,
    featureId: 'passwordManagement',
  }),
  radiusLink: new NavPanelLinkListItem({
    title: 'RADIUS',
    href: '#/radius',
    imageComponent: iconRadius,
  }),
  reportsLink: new NavPanelLinkListItem({
    href: '#/reports',
    imageComponent: iconReports,
    title: 'Reports',
  }),
  saasManagementLink: new NavPanelLinkListItem({
    title: 'SaaS Management',
    href: '#/saas-management',
    imageComponent: iconSaasManagement,
    isNew: true,
  }),
  settingsLink: new NavPanelLinkListItem({
    title: 'Settings',
    href: '#/settings',
    imageComponent: iconSettings,
  }),
  softwareAppsLink: new NavPanelLinkListItem({
    title: 'Software Management',
    href: '#/managedSoftware/windows',
    imageComponent: iconSoftwareApps,
  }),
  ssoAppsLink: new NavPanelLinkListItem({
    title: 'SSO Applications',
    href: '#/applications',
    imageComponent: iconSso,
  }),
  userGroupsLink: new NavPanelLinkListItem({
    title: 'User Groups',
    href: '#/groups/user',
    imageComponent: iconGroups,
  }),
  usersLink: new NavPanelLinkListItem({
    title: 'Users',
    href: '#/users',
    imageComponent: iconUsers,
  }),
};

export default NavPanelLinkList;
