import { onBeforeUnmount, onMounted, ref } from 'vue';

export const useResizeObserver = (
  target: HTMLElement,
  handler: (entry: ResizeObserverEntry) => void,
) => {
  const observer = ref<ResizeObserver>();

  onMounted(() => {
    observer.value = new ResizeObserver(([entry]) => handler(entry));

    observer.value.observe(target);
  });

  onBeforeUnmount(() => {
    observer.value?.disconnect();
  });

  return {
    observer,
  };
};
