import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';

export const {
  FeatureFlagMixin,
  FeatureFlagsModule,
  initializeFlags,
  useFeatureFlags,
} = setupFeatureFlags([
  {
    localName: 'deviceHealthMonitoring',
    sourceId: 'LD-2024-03-01-monitoring',
  },
  {
    localName: 'workflowsManagement',
    sourceId: 'LD-2024-07-15-request-approval-workflow',
  },
  {
    localName: 'cortexRelease',
    sourceId: 'PLXB-404-nli-cortex-release',
  },
  {
    localName: 'cortexFts',
    sourceId: 'PLXB-404-nli-cortex-fts',
  },
  {
    localName: 'cortexWorkflows',
    sourceId: 'PLXB-404-nli-cortex-workflows',
  },
  {
    localName: 'policyManagementDeliveryType',
    sourceId: 'TH-750-ap-policy-management-delivery-type',
  },
  {
    localName: 'passwordPoliciesMfe',
    sourceId: 'AUTH-10461-multiple-password-policies',
  },
  {
    localName: 'assetManagementMFE',
    sourceId: 'VR-2025-02-12-asset-management-mfe',
  },
]);
