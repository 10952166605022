import { h, toRefs } from 'vue';
import MarkdownIt from 'markdown-it';

/**
 * ui-components MarkdownBlock / MarkdownInline don't support full document rendering
 */
export default {
  name: 'MarkdownDocument',
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  setup(props: { source: string }) {
    const { source } = toRefs(props);
    const md = MarkdownIt({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
    });
    const html = md.render(source.value);

    return () => h('div', {
      innerHTML: html,
      style: 'font-size: var(--jcBody)',
      class: 'markdownDocument',
    });
  },
};
