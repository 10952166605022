import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import type { App as VueApp } from 'vue';

import { router } from './router';
import { store } from './store/index';

import '@jumpcloud-ap/time-based-trial-vue3/css';
import '@jumpcloud/ui-components/css';
import { EventsPlugin } from '@jumpcloud/ui-events-vue3';
import { FeatureFlagMixin, initializeFlags } from './feature-flags';
import { TestIdMixin } from '@jumpcloud-ap/test-id';
import { addRootClasses } from '@jumpcloud/ui-components';
import { buildUiEventsOptions } from '@jumpcloud-ap/ui-events-setup';
import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import { logAppMounted } from '@jumpcloud-ap/logger';
import { useCoreUserInfo } from '@jumpcloud-ap/core-user-info';

const pinia = createPinia();

let app: VueApp<Element> | null = null;

export const destroy = () => {
  if (app) {
    app.unmount();
    app = null;
  }
};

export const initialize = async () => {
  if (app) {
    destroy();
  }

  addRootClasses(null);

  const coreInfo = useCoreUserInfo();
  const axiosInstance = createJcApiAxiosInstance();

  await (async () => {
    const settings = await fetch('/ui-settings.json').then(r => r.json());

    try {
      await initializeFlags({
        axiosInstance,

        disableStreaming: settings.launchDarklyDisableStreaming,

        identity: {
          id: coreInfo.isAnMsp.value
            ? coreInfo.providerId.value ?? coreInfo.orgId.value!
            : coreInfo.orgId.value!,

          name: coreInfo.name.value ?? '',
        },

        launchDarklyConfig: {
          baseUrls: settings.launchDarklyBaseUrls,
          clientSideId: settings.launchDarklyClientSideId,
        },

        source: settings.launchDarklySource === 'localStorage'
          ? 'localStorage'
          : 'launchDarkly',
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error: Unable to fetch feature flags. Unexpected behavior may occur.', e);
    }
  })();
  const container = document.getElementById('single-spa-application:@jumpcloud-ap/sidenav_app')!;
  const root = document.createElement('div');
  container.appendChild(root);

  app = createApp(App)
    .use(router)
    .use(store)
    .use(pinia)
    .use(EventsPlugin, buildUiEventsOptions())
    .mixin(TestIdMixin)
    .mixin(FeatureFlagMixin);

  app.mount(root);

  coreInfo.cleanup();

  try {
    logAppMounted(process.env.VITE_APP_NAME, process.env.VITE_VERSION);
  } catch {
    // empty
  }
};
