import { GoToResult } from '../GoToResult';

import iconIdea from '@/img/icons/cortex/icon-idea.svg';

import { useCortexStore } from '@/store/cortex';
import type { CortexSearchResult } from '@/api/CortexApi';
import type { GoToResultRecentKey } from '../GoToResult';
import type { Provider } from '../Provider';

/**
 * @description Provider class implemented as a composable. Due to how cortex functions we need to
 * call a composable method when the search term is updated. Since the Provider class pattern
 * is only used in a composable, the Provider itself can be a composable which allows for using
 * other composables.
 */
export const useCortexSection = (): Provider => {
  const cortex = useCortexStore();
  const buildCortexResult = (searchTerm: string, type: CortexSearchResult['type']) : GoToResult => {
    const title = ['dsl', 'fts'].includes(type)
      ? type.toUpperCase()
      : `${type[0].toUpperCase()}${type.slice(1)}`;

    return new GoToResult({
      title,
      subtitle: searchTerm,
      imageComponent: iconIdea,
      recentKey: {
        type: `cortex:${type}`,
        id: searchTerm,
      },
    });
  };

  const lookup = async (recentKey: GoToResultRecentKey) : Promise<GoToResult | undefined> => {
    if (recentKey.type.includes('cortex')) {
      const [,type] = recentKey.type.split(':');
      return buildCortexResult(recentKey.id, type as CortexSearchResult['type']);
    }

    return undefined;
  };

  const updateSearchTerm = async (searchTerm: string): Promise<GoToResult[]> => {
    if (searchTerm === '') {
      return [];
    }

    await cortex.askQuestion(searchTerm);

    const cortexResult = cortex.prompt.data?.results?.length
      ? [buildCortexResult(searchTerm, cortex.prompt.data.results![0].type)]
      : [];

    return cortexResult;
  };

  return {
    lookup,
    updateSearchTerm,
  };
};
