<template>
  <section class="CortexPanel jc-scrollbar jc-scrollbar-vertical">
    <ButtonBack
      v-if="cortex.hasError"
      class="backButton"
      @click="clearPrompt"
    />

    <template v-if="cortex.prompt.data && !cortex.hasError">
      <header
        class="header"
        :data-test-id="$testId('header')"
      >
        <ParagraphBody
          v-if="!cortex.hasLlmError && !(cortex.isFts || cortex.isWorkflow)"
          class="headerMessage"
          :data-test-id="$testId('headerMessage')"
        >
          <IconGenAi
            class="cortexIcon"
            role="presentation"
          />
          <MarkdownInline :mdTemplate="headerMessage" />
        </ParagraphBody>
        <div
          v-if="!cortex.hasLlmError && !(cortex.isFts || cortex.isWorkflow)"
          class="feedbackControls"
        >
          <button
            aria-label="Rate this response +1"
            class="feedbackButton"
            :data-test-id="$testId('buttonThumbsUp')"
            :disabled="!!feedbackScore && !cortex.feedback.isLoading"
            @click="cortex.submitFeedback(cortex.prompt.data.uuid, 1)"
          >
            <IconThumbsUp
              :class="['feedbackIcon', {
                'feedbackIcon--highlight': feedbackScore === 1,
                'feedbackIcon--disabled': feedbackScore === -1,
              }]"
              role="presentation"
            />
          </button>
          <button
            aria-label="Rate this response -1"
            class="feedbackButton"
            :data-test-id="$testId('buttonThumbsDown')"
            :disabled="!!feedbackScore && !cortex.feedback.isLoading"
            @click="cortex.submitFeedback(cortex.prompt.data.uuid, -1)"
          >
            <IconThumbsDown
              :class="['feedbackIcon', {
                'feedbackIcon--highlight': feedbackScore === -1,
                'feedbackIcon--disabled': feedbackScore === 1,
              }]"
              role="presentation"
            />
          </button>
        </div>
      </header>

      <CortexDslView
        v-if="cortex.isDsl"
        :data-test-id="$testId('dslView')"
        @navigation="emit('navigation')"
      />

      <!-- inline style is just a hack while this is in debug mode -->
      <MarkdownDocument
        v-if="cortex.isFts || cortex.isWorkflow"
        :key="cortex.prompt.data!.uuid"
        :source="`\`\`\`json\n${JSON.stringify(cortex.prompt.data, null, 2)}\n\`\`\``"
      />

      <CortexSupportView v-if="cortex.isSupport" />
    </template>

    <CortexSuggestions
      v-else-if="cortex.hasError"
      :data-test-id="$testId('suggestions')"
    />

    <CortexLander
      v-else
      :data-test-id="$testId('lander')"
    />

    <LoadingOverlay :visible="cortex.isLoading" />
  </section>
</template>

<script setup lang="ts">
import {
  ButtonBack,
  LoadingOverlay,
  MarkdownInline,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed, onBeforeMount } from 'vue';
import { useCortexStore } from '@/store/cortex';
import CortexDslView from './CortexDslView.vue';
import CortexLander from './CortexLander.vue';
import CortexSuggestions from './CortexSuggestions.vue';
import CortexSupportView from './CortexSupportView.vue';
import IconGenAi from '@/img/icons/cortex/icon-gen-ai.svg';
import IconThumbsDown from '@/img/icons/cortex/icon-thumbs-down.svg';
import IconThumbsUp from '@/img/icons/cortex/icon-thumbs-up.svg';
import MarkdownDocument from './MarkdownDocument';

const cortex = useCortexStore();
const emit = defineEmits(['navigation']);

/**
 * For support results we show the output as a markdown document. For some dsl results we will have
 * both output and rationale, and others only rationale. For dsl if rationale is not returned we
 * show the output instead.
 */
const headerMessage = computed(() => (
  cortex.isDsl
    ? cortex.output || cortex.rationale
    : cortex.rationale
));

const feedbackScore = computed(() => (
  cortex.prompt.data
    ? cortex.feedback.data![cortex.prompt.data!.uuid]
    : null
));

const clearPrompt = () => {
  cortex.updateSearchTerm('');
  cortex.clearErrors();
};

onBeforeMount(() => {
  cortex.fetchSchema();
});
</script>

<script lang="ts">
export default {
  name: 'CortexPanel',
};
</script>

<style scoped>
.CortexPanel {
  background-color: var(--jc-color-background);
  height: 100%;
  padding: var(--jc-spacer);
  position: relative;
}

.backButton {
  margin-bottom: var(--jc-spacer);
}

.header, .headerMessage, .feedbackControls {
  display: flex;
  gap: var(--jc-spacer-small);
}

.headerMessage {
  margin: 0 var(--jc-spacer) 0 0 !important;
}

.header {
  margin-bottom: var(--jc-spacer);
}

.cortexIcon {
  align-self: flex-start;
  display: inline-block;
  flex-shrink: 0;
  height: 24px;
  margin-right: var(--jc-spacer-small);
  margin-top: 6px;
  width: 48px;
}

.feedbackControls {
  align-items: center;
  margin-left: auto;
}

.feedbackButton {
  background-color: transparent;
  cursor: pointer;
  padding: var(--jc-spacer-x-small);
}

.feedbackButton:focus {
  box-shadow: var(--jc-focus-outline);
}

.feedbackIcon--highlight {
  color: var(--jc-color-primary-fill-default);
}

.feedbackIcon--disabled {
  color: var(--jcGrey400);
}

:deep(pre) {
  white-space: pre-wrap;
}
</style>
