import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import type { SearchQueryRequest } from './SearchApi';

export interface CortexResult<T, R> {
  type: T;
  result: R;
  output: string;
  error: boolean;
}

export type DslResult = CortexResult<'dsl', SearchQueryRequest>;

// FTS results aren't functional yet, this type likely to change
export type FtsResult = CortexResult<'fts', {
  query: string;
}>;

export interface SupportDocument {
  content: string;
  created_at: string;
  deleted_at: string | null;
  embedding: string | null;
  id: number;
  relevancy: number;
  title: string;
  updated_at: string | null;
  url: string;
}

export type SupportResult = CortexResult<'support', {
  count: number;
  documents: SupportDocument[];
}>;

// Workflow results aren't functional yet, this type likely to change
export type WorkflowResult = CortexResult<'workflow', SearchQueryRequest>;

export type CortexSearchResult =
| DslResult
| FtsResult
| SupportResult
| WorkflowResult;

export interface CortexSearchResponse {
  // totalCount: number;
  uuid: string;
  rationale: string;
  results: CortexSearchResult[];
}

class CortexApi {
  private baseUrl = '/api/v2/nli';
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  get feedbackUrl() {
    return `${this.baseUrl}/feedback`;
  }

  get searchUrl() {
    return `${this.baseUrl}/query`;
  }

  feedback(uuid: string, score: number) {
    return this.axiosInstance.post(this.feedbackUrl, { uuid, score })
      .then(res => res.data);
  }

  search(query: string) : Promise<CortexSearchResponse> {
    return this.axiosInstance.post<CortexSearchResponse>(this.searchUrl, { query })
      .then((res) => res.data);
  }
}

const api = new CortexApi();
export default api;
