import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useFeatureFlags } from '@/feature-flags';
import CortexApi from '@/api/CortexApi';
import SearchApi from '@/api/SearchApi';
import type { CortexSearchResponse, DslResult } from '@/api/CortexApi';
import type { SearchQueryResponse, SearchSchemaObject, SearchSchemaResponse } from '@/api/SearchApi';

interface CortexFetchSchema<T> {
  isLoading: boolean;
  error: Error | null;
  data: T | null;
}

type FeedbackDict = Record<string, -1 | 1>;

const flattenSchema = (schema: SearchSchemaResponse) => schema.objects
  .reduce((acc, entry) => {
    // eslint-disable-next-line no-param-reassign
    acc[entry.name] = entry;

    return acc;
  }, {} as Record<string, SearchSchemaObject>);

export const useCortexStore = defineStore('cortex', () => {
  const { hasFeatureFlag } = useFeatureFlags();
  const schema = reactive<CortexFetchSchema<Record<string, SearchSchemaObject>>>({
    isLoading: false,
    error: null,
    data: null,
  });
  const searchTerm = reactive({
    value: '',
  });
  const updateSearchTerm = (value: string) => {
    searchTerm.value = value;
  };

  const prompt = reactive<CortexFetchSchema<CortexSearchResponse>>({
    isLoading: false,
    error: null,
    data: null,
  });
  const promptResults = computed<CortexSearchResponse['results']>(() => prompt.data?.results || []);
  const promptType = computed(() => promptResults.value[0]?.type);
  const rationale = computed<string>(() => prompt.data?.rationale || '');
  const output = computed<string>(() => promptResults.value[0]?.output || '');
  const hasLlmError = computed(() => !!prompt.data?.results?.[0]?.error);

  const isDsl = computed(() => promptType.value === 'dsl');
  const isFts = computed(() => promptType.value === 'fts');
  const isSupport = computed(() => promptType.value === 'support');
  const isWorkflow = computed(() => promptType.value === 'workflow');

  const itemType = computed(() => {
    if (!isDsl.value || !promptResults.value.length) return null;

    const { fields } = (promptResults.value as DslResult[])[0].result;

    if (!fields?.include.length) return null;

    // split the first field to get the item type
    // e.g. 'device.id' -> ['device', 'id']
    const [value] = fields.include[0].split('.');

    return value;
  });

  const itemSchema = computed(() => schema.data?.[itemType.value || ''] || null);

  const feedback = reactive<CortexFetchSchema<FeedbackDict>>({
    isLoading: false,
    error: null,
    data: {},
  });

  const search = reactive<CortexFetchSchema<SearchQueryResponse>>({
    isLoading: false,
    error: null,
    data: null,
  });
  const searchLimit = computed(() => (promptResults.value as DslResult[])?.[0]?.result?.limit || 0);
  const searchTotalCount = computed(() => search.data?.results?.length || 0);

  const hasError = computed<boolean>(() => !!(schema.error || prompt.error || search.error));
  const isLoading = computed<boolean>(() => (
    schema.isLoading || prompt.isLoading || search.isLoading
  ));

  const clearErrors = () => {
    schema.error = null;
    prompt.error = null;
    search.error = null;
    feedback.error = null;
  };

  const clearSearch = () => {
    search.data = null;
  };

  const clearPrompt = () => {
    clearErrors();

    prompt.data = null;
  };

  const fetchSchema = async () => {
    schema.isLoading = true;

    try {
      const res = await SearchApi.schema();

      schema.data = flattenSchema(res);
    } catch (e) {
      schema.error = e as Error;
    } finally {
      schema.isLoading = false;
    }
  };

  const fetchSearchResults = async () => {
    if (!prompt.data || !isDsl.value) return;

    search.isLoading = true;

    try {
      const res = await SearchApi.query((promptResults.value as DslResult[])![0].result);

      search.data = res;
    } catch (e) {
      search.error = e as Error;
    } finally {
      search.isLoading = false;
    }
  };

  const askQuestion = async (query: string) => {
    clearErrors();

    prompt.isLoading = true;

    try {
      const res = await CortexApi.search(query);

      prompt.data = res;
    } catch (e) {
      prompt.error = e as Error;
    } finally {
      prompt.isLoading = false;
    }

    /**
     * @todo: At the moment we only send requests to /search/query for DSL responses. Once FTS and
     * Workflows are functional this condition will need to be changed to `!isSupport.value` and
     * the FF checks below can be removed.
     */
    if (isDsl.value) {
      await fetchSearchResults();
    }

    if (isWorkflow.value && !hasFeatureFlag('cortexWorkflows')) {
      prompt.error = new Error('Workflows feature flag not enabled');
      prompt.data = null;
    }

    if (isFts.value && !hasFeatureFlag('cortexFts')) {
      prompt.error = new Error('FTS feature flag not enabled');
      prompt.data = null;
    }
  };

  const submitFeedback = async (uuid: string, score: -1 | 1) => {
    feedback.isLoading = true;

    try {
      await CortexApi.feedback(uuid, score);

      feedback.data![uuid] = score;
    } catch (e) {
      feedback.error = e as Error;
    } finally {
      feedback.isLoading = false;
    }
  };

  const getFeedback = (uuid: string) => feedback.data![uuid] || null;

  return {
    feedback,
    schema,

    search,
    searchLimit,
    searchTotalCount,

    searchTerm,
    updateSearchTerm,

    prompt,
    promptResults,
    promptType,
    rationale,
    output,
    isDsl,
    isFts,
    isSupport,
    isWorkflow,
    itemType,
    itemSchema,

    hasError,
    hasLlmError,
    isLoading,

    clearErrors,
    clearSearch,
    clearPrompt,
    fetchSchema,
    askQuestion,
    submitFeedback,
    getFeedback,
  };
});
