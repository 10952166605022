<template>
  <Card
    class="CortexSupportView"
    hasFooterDivider
  >
    <template #body>
      <MarkdownDocument
        :key="cortex.prompt.data!.uuid"
        class="markdownDocument"
        :source="cortex.output"
      />
    </template>

    <template #footer>
      <template v-if="documents.length">
        <ParagraphBody>The following articles may also be helpful</ParagraphBody>
        <ul class="supportList">
          <li
            v-for="document, i in documents"
            :key="i"
          >
            <Link
              class="link"
              :href="document.url"
              isExternal
              target="_blank"
            >
              {{ document.title }}
            </Link>
          </li>
        </ul>
      </template>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import {
  Card,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed } from 'vue';
import { useCortexStore } from '@/store/cortex';
import MarkdownDocument from './MarkdownDocument';
import type { SupportDocument, SupportResult } from '@/api/CortexApi';

const cortex = useCortexStore();

const documents = computed<SupportDocument[]>(
  () => ((cortex.promptResults?.[0] as SupportResult)?.result?.documents || []),
);
</script>

<style scoped>
.CortexSupportView {
  box-shadow: var(--jc-box-shadow);
}

.markdownDocument, .supportList {
  line-height: 1.5;
}

.markdownDocument :deep(pre) {
  background-color: var(--jc-color-disabled);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius);
  padding: var(--jc-spacer-small);
  white-space: pre-wrap;
}

.link {
  font-size: var(--jcBody);
}
</style>
