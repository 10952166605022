/**
 * @module NavPanelLinkListItem
 */

/**
 * @typedef NavPanelLinkListItem
 * @property {string} href
 * @property {*} imageComponent
 * @property {boolean} isNew
 * @property {string} title
 * @property {string} routeName
 * @property {string} featureFlag
 * @property {string} featureId
 */

/**
 * This funtion is used to create a new NavPanelLinkListItem for use in the old, or new, NavPanel.
 * @param {NavPanelLinkListItem} params
 * @returns {NavPanelLinkListItem}
 */

import { markRaw } from 'vue';

export default function NavPanelLinkListItem(params = {}) {
  const {
    featureFlag,
    featureId,
    href,
    imageComponent,
    isNew = false,
    showForCommandRunners,
    title,
  } = params;

  this.featureFlag = featureFlag;
  this.href = href;
  this.imageComponent = imageComponent ? markRaw(imageComponent) : undefined;
  this.isNew = isNew;
  this.showForCommandRunners = showForCommandRunners;
  this.title = title;
  this.featureId = featureId;
}
